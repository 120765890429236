import React, { useState } from "react"
import {
    CardBody,
    Container,
    Row,
    Col,
    Card,
    ModalBody,
    Badge,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import { toast } from "react-hot-toast"

import { SearchBox } from "components/SearchBox"
import { TableTopHeading } from "components/TableTopHeading"
import { BackButtonTableTop } from "components/BackButtonTableTop"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { TableHeaderButtons } from "components/TableHeaderButtons"
import { PageLimit } from "components/PageLimit"
import { Button } from "components/Button"
import { Modal } from "components/Modal"
import { UsedCount } from "components/UsedCount"
import { Counter } from "components/Counter"
import { TextInput } from "components/TextInput"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import InventoryTitleIcon from "assets/images/icons/inventory-title-icon.png"
import { ScrapReturnForm } from "pages/Common/ScrapReturn"
import CommittedInventoryListing from "./CommittedInventoryListing"
import AddNewItemModal from "./AddNewItemModal"
import { getAppName } from "utils/commonUtils"
import {
    CreateItemAudit,
    CreateScrapReturnRequest,
    GetDepartmentInventoryListing,
    MarkInventoryCommitted,
    MarkInventoryUsage,
} from "api/api.service"
import ItemCatalogListing from "./ItemCatalogListing"
import { Refresh } from "../../../components/Refresh"
import useDepartmentConfig from "hooks/useDepartmentConfig"
import AuditItemModal from "./AuditItemModal"

const COLUMNS = {
    CHECK_BOXES: "",
    SKU_NO: "SKU No.",
    ITEM_NAME: "Item Name",
    COMMITTED: "Committed",
    IN_STOCK: "Total",
    AVAILABLE: "Available",
    AUDIT: "Audit",
    GLOBAL: "Global",
    LAST_AUDIT_DATE: "Last Audit Date",
    LAST_UPDATED_DATE: "Last Updated",
    ACTION: "",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const InventoryListing = ({}) => {
    const { currentDepartmentId, currentDepartmentName } = useDepartmentConfig()
    const history = useHistory()
    const [isSubmittingScrap, setIsSubmittingScrap] = useState(false)
    const [itemDes, setItemDes] = useState("")
    const [selectedItems, setSelectedItems] = useState([])
    const [committedValue, setCommittedValue] = useState("")
    const [usedValue, setUsedValue] = useState("")
    const [committedInvModal, setCommittedInvenModal] = useState("")
    const [selectedItemForAudit, setSelectedItemForAudit] = useState(null)
    const [usedInvModal, setUsedInvenModal] = useState(false)
    const [countInvModal, setCountInvenModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [filters, setFilters] = useState(null)
    const [isActive, setIsActive] = useState({
        departmentRequest: true,
        pullBackRequest: false,
    })
    const [modalStates, setModalStates] = useState({
        addNewReqModal: false,
        scrapReturnModal: false,
        committedInvenModal: false,
        usedInvenModal: false,
        countModal: false,
        addNewRequestModal: false,
        showItemDesModal: false,
        viewItemCatalog: false,
        auditModal: false,
    })

    const {
        isFetching,
        page,
        searchText,
        pageSize,
        data,
        total,
        request,
        handlePageClick,
        handleSearchChange,
        onChangePageSize,
    } = useLocalPaginatedRequest({
        requestFn: GetDepartmentInventoryListing,
        params: {
            ...(filters?.Status && { Status: filters?.Status }),
            departmentId: [currentDepartmentId],
        },
        deps: [filters, isActive, currentDepartmentId],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const handleCheckedBoxSelection = data => {
        let isAdded = selectedItems?.find(item => item.id == data.id)

        if (isAdded) {
            let updatedItems = selectedItems?.filter(item => item.id != data.id)
            setSelectedItems([...updatedItems])
        } else {
            setSelectedItems(prev => {
                return [
                    ...prev,
                    { ...data, requiredQuantity: "", hasError: false },
                ]
            })
        }
    }

    const headerButtonsData = [
        {
            title: "Add New Request",
            onClick: () =>
                setModalStates(prev => ({
                    ...prev,
                    addNewReqModal: !modalStates.addNewReqModal,
                })),

            classesButton: "ms-0 ms-md-2",
        },
        {
            title: "Scrap/Return",
            onClick: () =>
                setModalStates(prev => ({
                    ...prev,
                    scrapReturnModal: !modalStates.scrapReturnModal,
                })),
            classesButton: "ms-2",
        },
    ]

    const handleCloseModal = () => {
        setModalStates({
            scrapReturnModal: false,
            committedInvenModal: false,
            usedInvenModal: false,
            countModal: false,
            auditQuantity: false,
        })
    }

    // seperated as it need to perform more functionality
    const handleAddNewReqModalClose = () => {
        setModalStates({ ...modalStates, addNewReqModal: false })
        setSelectedItems([])
    }

    const handleMarkInventoryCommitted = async () => {
        if (!committedInvModal) return

        let payload = {
            itemsId: committedInvModal.itemsId,
            quantity: Math.abs(committedValue),
            departmentId: currentDepartmentId,
        }

        try {
            setIsLoading(true)
            let res = await MarkInventoryCommitted(payload)
            toast.success("Success !")
            request()
        } catch (error) {
            // toast.error(error.message)
        } finally {
            setSelectedItems([])
            setCommittedValue("")
            setCommittedInvenModal("")
            setIsLoading(false)
        }
    }

    const handleMarkInventoryUsage = async () => {
        if (!usedInvModal) return

        let payload = {
            itemsId: usedInvModal.itemsId,
            quantity: Math.abs(usedValue),
            departmentId: currentDepartmentId,
        }

        try {
            setIsLoading(true)
            let res = await MarkInventoryUsage(payload)
            toast.success("Success !")
            request()
        } catch (error) {
            // toast.error(error.message)
        } finally {
            setUsedInvenModal("")
            setUsedValue("")
            setSelectedItems([])
            setIsLoading(false)
        }
    }

    const handleSetCommited = evt => {
        if (
            evt.target.value >
            committedInvModal.inStock - committedInvModal.committed
        )
            return
        setCommittedValue(evt.target.value)
    }

    const handleSetUsed = evt => {
        if (evt.target.value > usedInvModal.committed) return
        setUsedValue(evt.target.value)
    }

    const handleCloseDesModal = () => {
        setItemDes("")
        setModalStates(prev => ({
            ...prev,
            showItemDesModal: false,
            viewItemCatalog: false,
        }))
    }

    const handleOpenDesModal = data => {
        setItemDes(data)
        setModalStates(prev => ({ ...prev, showItemDesModal: true }))
    }
    const handleOpenItemCatalogModal = data => {
        setModalStates(prev => ({ ...prev, viewItemCatalog: true }))
    }
    const handleToggleAuditModal = (data = null) => {
        setSelectedItemForAudit(data)
        setModalStates(prev => ({
            ...prev,
            auditModal: !modalStates.auditModal,
        }))
    }

    const handleSubmitScrap = async payload => {
        try {
            setIsSubmittingScrap(true)
            let res = await CreateScrapReturnRequest(payload)
            toast.success("Request has been created successfully !")
            request()
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsSubmittingScrap(false)
            handleCloseModal()
            setSelectedItems([])
        }
    }

    const handleSubmitAudit = async () => {
        const payload = {
            itemsId: selectedItemForAudit.itemsId,
            quantity: +selectedItemForAudit.auditQuantity,
        }
        try {
            setIsLoading(true)
            let res = await CreateItemAudit(payload)
            toast.success("Item has been updated successfully !")
            request()
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
            handleCloseModal()
            setSelectedItemForAudit(null)
        }
    }

    return (
        <>
            <div className="gt-business-accounts px-3 px-md-5">
                <Container fluid>
                    <Row>
                        <Col className="col-12 ">
                            <div className="d-flex align-items-center mb-2 mb-md-4">
                                <BackButtonTableTop />
                            </div>

                            <TableTopHeading
                                title={`Inventory (${getAppName()})`}
                                iconImage={InventoryTitleIcon}
                                iconClass="inventory-req-icon"
                            />

                            <Card className="mb-5">
                                <Refresh
                                    onClick={request}
                                    isDisable={isFetching}
                                />
                                <CardBody className="p-2 p-md-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5 className="fw-600 mb-0 table-main-heading">
                                            Inventory
                                        </h5>
                                        <div className="d-flex align-items-md-center justify-content-center flex-column flex-md-row align-items-end">
                                            <div>
                                                <SearchBox
                                                    searchText={searchText}
                                                    onChange={
                                                        handleSearchChange
                                                    }
                                                    inputClasses="table-main-search-input inventory-search-ip me-2"
                                                />
                                            </div>
                                            <div className="d-flex mt-2 mt-md-0 me-2 me-md-0">
                                                <TableHeaderButtons
                                                    data={headerButtonsData}
                                                    classes={
                                                        "gt-btn-grad-primary px-4 inv-btn-alignment"
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <PageLimit
                                            pageSize={pageSize}
                                            changePageSizeHandler={
                                                onChangePageSize
                                            }
                                            rowLimit={pageSize}
                                            typoClasses="page-limit-typo"
                                            selectClasses="page-limit-select"
                                        />

                                        <p
                                            className="m-0 p-0"
                                            style={{
                                                color: "#0080FF",
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                            }}
                                            onClick={handleOpenItemCatalogModal}
                                        >
                                            View Item Catalog
                                        </p>
                                    </div>

                                    <DataTable
                                        data={data}
                                        loading={isFetching}
                                        tableClasses="inventory-table"
                                        config={[
                                            {
                                                title: COLUMNS.CHECK_BOXES,
                                                render: data => {
                                                    return (
                                                        <div className="form-check">
                                                            <TextInput
                                                                onClick={() =>
                                                                    handleCheckedBoxSelection(
                                                                        data
                                                                    )
                                                                }
                                                                labelClass="mb-0"
                                                                type="checkbox"
                                                                inputClass="form-check-input-custom"
                                                                checked={
                                                                    selectedItems
                                                                        ? selectedItems?.find(
                                                                              item =>
                                                                                  item.id ==
                                                                                  data.id
                                                                          )
                                                                        : false
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                },
                                            },
                                            {
                                                title: COLUMNS.SKU_NO,
                                                render: data => {
                                                    return (
                                                        <>
                                                            <div
                                                                onClick={() =>
                                                                    handleOpenDesModal(
                                                                        data
                                                                    )
                                                                }
                                                                className="table-clickable-column"
                                                            >
                                                                {data?.skuNo ||
                                                                    "-"}
                                                            </div>
                                                        </>
                                                    )
                                                },
                                            },
                                            {
                                                title: COLUMNS.ITEM_NAME,
                                                render: data => {
                                                    return data?.itemName || "-"
                                                },
                                            },
                                            {
                                                title: COLUMNS.COMMITTED,
                                                render: data => {
                                                    return (
                                                        <div>
                                                            {data?.committed}
                                                        </div>
                                                    )
                                                },
                                            },
                                            {
                                                title: COLUMNS.AVAILABLE,
                                                render: data => {
                                                    return (
                                                        <div>
                                                            {data.inStock -
                                                                data?.committed}
                                                        </div>
                                                    )
                                                },
                                            },
                                            {
                                                title: COLUMNS.IN_STOCK,
                                                render: data => {
                                                    return (
                                                        <div>
                                                            {data.inStock}
                                                        </div>
                                                    )
                                                },
                                            },
                                            {
                                                title: COLUMNS.AUDIT,
                                                render: data => {
                                                    return (
                                                        <div>
                                                            {data.auditQuantity}
                                                        </div>
                                                    )
                                                },
                                            },
                                            {
                                                title: COLUMNS.LAST_UPDATED_DATE,
                                                render: data => {
                                                    return convertDateTime({
                                                        date: data.updatedAt,
                                                        customFormat:
                                                            dateTimeFormat.casitaCutomDateFormat,
                                                        dateOnly: true,
                                                    })
                                                },
                                            },
                                            // {
                                            //   title: COLUMNS.GLOBAL,
                                            //   render: data => {
                                            //     return <div>{data?.globalTotal || "-"}</div>
                                            //   },
                                            // },

                                            // {
                                            //   title: COLUMNS.LAST_AUDIT_DATE,
                                            //   render: data => {
                                            //     return convertDateTime({
                                            //       date: data.createdAt,
                                            //       customFormat: dateTimeFormat.casitaCutomDateFormat,
                                            //       dateOnly: true,
                                            //     })
                                            //   },
                                            // },
                                            {
                                                title: COLUMNS.ACTION,
                                                render: (data, index) => (
                                                    <div className="d-flex justify-content-evenly">
                                                        <Button
                                                            size={"lg"}
                                                            title="Audit"
                                                            onClick={() =>
                                                                handleToggleAuditModal(
                                                                    data
                                                                )
                                                            }
                                                            className="btn gt-btn gt-btn-null shadow-none table-action-btn btn btn-alignment me-2 gt-btn-grad-primary"
                                                        />

                                                        <Button
                                                            styles={{
                                                                height: "auto",
                                                                backgroundColor:
                                                                    "#0e3253",
                                                                opacity: "0.7",
                                                            }}
                                                            size={"lg"}
                                                            title="Commit Inventory"
                                                            className=" btn gt-btn gt-btn-null shadow-none   table-action-btn inventory-column-btns false btn  btn-alignment me-2 gt-btn-grad-primary "
                                                        />
                                                        <Button
                                                            styles={{
                                                                height: "auto",
                                                                cursor: "pointer",
                                                                backgroundColor:
                                                                    "#0e3253",
                                                                opacity: "0.7",
                                                            }}
                                                            size={"lg"}
                                                            title="Use Inventory"
                                                            className="btn gt-btn gt-btn-null shadow-none   table-action-btn inventory-column-btns false btn btn-alignment gt-btn-grad-primary"
                                                        />

                                                        {/* <Button
                                                                styles={{ height: "auto", cursor: "pointer" }}
                                                                key={index}
                                                                size={"lg"}
                                                                title="Counts"
                                                                onClick={() => setCountInvenModal(data)}
                                                                className="btn gt-btn gt-btn-null shadow-none gt-btn-grad-outline-type  table-action-btn inventory-column-btns false btn btn-secondary"
                                                        /> */}
                                                    </div>
                                                ),
                                            },
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={total}
                                        onPageClick={handlePageClick}
                                        rowLimit={pageSize}
                                    />
                                </CardBody>
                            </Card>
                            <div className="text-center ">
                                <Button
                                    size="lg"
                                    className="gt-btn-grad-primary table-bottom-btn btn-alignment mb-3"
                                    title={<div>Back to Main</div>}
                                    onClick={history.goBack}
                                />
                            </div>
                        </Col>
                    </Row>
                    {/* :TODO: Need to separate all these popup as a component */}

                    {modalStates.auditModal && (
                        <Modal
                            modalTitle="Audit"
                            isOpen={modalStates.auditModal}
                            handleModalToggling={() =>
                                handleToggleAuditModal(null)
                            }
                            onSubmit={handleSubmitAudit}
                            isLoading={isLoading}
                            size="sm"
                        >
                            <AuditItemModal
                                value={selectedItemForAudit.auditQuantity}
                                onChange={e =>
                                    setSelectedItemForAudit(prev => ({
                                        ...prev,
                                        auditQuantity: e.target.value,
                                    }))
                                }
                                onSubmit={handleSubmitAudit}
                            />
                        </Modal>
                    )}

                    {modalStates.scrapReturnModal && (
                        <Modal
                            isOpen={modalStates.scrapReturnModal}
                            handleModalToggling={handleCloseModal}
                            sizeClasses="10"
                        >
                            <ScrapReturnForm
                                quantityKey="committed"
                                isReadOnly={false}
                                isSubmitting={isSubmittingScrap}
                                apiFunction={GetDepartmentInventoryListing}
                                onSubmit={handleSubmitScrap}
                            />
                        </Modal>
                    )}

                    {committedInvModal && (
                        <Modal
                            isOpen={committedInvModal}
                            handleModalToggling={() =>
                                setCommittedInvenModal("")
                            }
                            sizeClasses="10"
                        >
                            <CommittedInventoryListing
                                data={committedInvModal}
                                value={committedValue}
                                onChange={e => handleSetCommited(e)}
                            />
                            <div className="mt-3 text-center">
                                <Button
                                    disabled={!Boolean(committedValue > 0)}
                                    title="Update"
                                    className="gt-btn-grad-primary btn-alignment"
                                    isLoading={isLoading}
                                    onClick={handleMarkInventoryCommitted}
                                />
                            </div>
                        </Modal>
                    )}

                    {usedInvModal && (
                        <Modal
                            isOpen={usedInvModal}
                            handleModalToggling={() => setUsedInvenModal("")}
                            // size="md"
                            sizeClasses="5"
                        >
                            <div className="d-flex flex-column align-items-center">
                                <h3>USE INVENTORY</h3>
                                <p>Items Used / Committed</p>
                                <UsedCount
                                    value={usedValue}
                                    onChange={e => handleSetUsed(e)}
                                    max={usedInvModal.committed}
                                    total={usedInvModal?.committed}
                                />

                                <Button
                                    title="Update"
                                    className="gt-btn-grad-primary mt-5 btn-alignment"
                                    isLoading={isLoading}
                                    disabled={!Boolean(usedValue > 0)}
                                    onClick={handleMarkInventoryUsage}
                                />
                            </div>
                        </Modal>
                    )}

                    {countInvModal && (
                        <Modal
                            isOpen={countInvModal}
                            handleModalToggling={() => setCountInvenModal("")}
                            // size="md"
                            sizeClasses="5"
                        >
                            <div className="d-flex flex-column align-items-center">
                                <h3>COUNT</h3>

                                <Counter
                                    btnContainerStyles={{ width: "150px" }}
                                    btnContainerClasses="mt-3"
                                />
                                <Button
                                    title="Done"
                                    className="gt-btn-grad-primary mt-5 btn-alignment"
                                    isLoading={isLoading}
                                />
                            </div>
                        </Modal>
                    )}

                    {modalStates.addNewReqModal && (
                        <Modal
                            isOpen={modalStates.addNewReqModal}
                            handleModalToggling={handleAddNewReqModalClose}
                            bodyClassName="p-0"
                            customButton={true}
                            hideModalHeaderSeparator={true}
                            headerClasses="pb-0"
                            className="inv-req-popup-viewdetails"
                            sizeClasses="10"
                        >
                            <AddNewItemModal
                                modalCloseHandler={handleAddNewReqModalClose}
                                selectedData={selectedItems}
                                id={currentDepartmentId}
                            />
                        </Modal>
                    )}
                    {modalStates.showItemDesModal && (
                        <Modal
                            isOpen={modalStates.showItemDesModal}
                            handleModalToggling={handleCloseDesModal}
                            bodyClassName="p-0"
                            customButton={true}
                            hideModalHeaderSeparator={true}
                            headerClasses="pb-0"
                            className="inv-req-popup-viewdetails"
                            sizeClasses="7"
                        >
                            <h3 className="text-center inv-req-popup-heading">{`${itemDes?.itemName} (${itemDes.skuNo})`}</h3>
                            <div className="p-4">
                                <h4 className="inventory-description">
                                    Product Summary
                                </h4>
                                <p className="mt-1">{`${
                                    itemDes?.description || "-"
                                }`}</p>
                            </div>
                        </Modal>
                    )}

                    {modalStates.viewItemCatalog && (
                        <Modal
                            isOpen={modalStates.viewItemCatalog}
                            handleModalToggling={handleCloseDesModal}
                            bodyClassName="p-0"
                            customButton={true}
                            hideModalHeaderSeparator={true}
                            headerClasses="pb-0"
                            className="inv-req-popup-viewdetails"
                            sizeClasses="10"
                        >
                            <ModalBody className="inventory-catalog-modal">
                                <ItemCatalogListing
                                    departmentId={currentDepartmentId}
                                    departmentName={currentDepartmentName}
                                />
                            </ModalBody>
                        </Modal>
                    )}
                </Container>
            </div>
        </>
    )
}

export default InventoryListing

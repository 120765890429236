import React from "react"
import { Button, Input } from "reactstrap"

const AuditItemModal = ({ onChange, value, isLoading, onSubmit }) => {
    return (
        <form onSubmit={onSubmit}>
            <div className="d-flex align-items-center justify-content-center flex-column">
                <Input
                    onKeyDown={e => preventUnwantedInput(e)}
                    required
                    type="number"
                    value={value}
                    onChange={onChange}
                    step={"0.01"}
                    min={0}
                />
                <div>
                    <Button
                        type="submit"
                        className="gt-btn-grad-primary mt-2 "
                        isLoading={isLoading}
                    >
                        Update
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default AuditItemModal
